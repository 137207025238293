@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}
::-webkit-scrollbar-thumb {
  background: #d9d9d9 !important;
}
::-webkit-scrollbar-thumb {
  background: #b0b0b0 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-popper {
  transform: translate3d(169px, -143px, 0px) !important;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-600 {
  font-weight: 600;
}
.title-font {
  font-size: 18px !important;
  color: #101828;
}
.label-font {
  font-size: 14px !important;
  font-weight: bold !important;
  color: #344054 !important;
  margin-top: 1rem;
}
.p-0 {
  padding: 0px !important;
}
.sidebar-icon {
  color: #757575; /* Default icon color */
}

.iconHover:hover path {
  stroke: #ED6C02;
}
.activeItem path{
  stroke: #ED6C02;
  
}
.active-icon {
  color: #ED6C02 !important; 
}